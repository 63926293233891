import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import CashbackBg from './assets/cashback-bg.webp';

import styles from './part-get-cashback.module.scss';

export default function PartGetCashback() {
  return (
    <div className={styles.partGetCashback}>
      <PartLazyImage
        src={CashbackBg}
        className={styles.partGetCashback__background}
      />
      <div className={styles.partGetCashback__content}>
        <div className={styles.partGetCashback__title}>
          REPLACE YOUR WINDOWS & DOORS WITH THE COMPANY ONTARIO HOMEOWNERS TRUST MOST!
          {' '}
        </div>
        <div className={styles.partGetCashback__text}>
          We are proud to be family-owned and operated for over 30 years. All our windows and doors are made locally. All our installations come with a LIFETIME GUARANTEE, we offer 32% OFF on installation and 0% financing!
        </div>
      </div>
    </div>
  );
}
