import cx from 'classnames';
import React, { useState } from 'react';

import PartLazyImage from '../../part-lazy-image';

import BannerImageMD from './assets/first-screen-banner-image-MD.webp';
import BannerImagePlaceholderMD from './assets/first-screen-banner-image-MD.webp?placeholder';
import BannerImageSM from './assets/first-screen-banner-image-SM.webp';
import BannerImagePlaceholderSM from './assets/first-screen-banner-image-SM.webp?placeholder';
import BannerImage from './assets/first-screen-banner-image.webp';
import BannerImagePlaceholder from './assets/first-screen-banner-image.webp?placeholder';
import ModalBg from './assets/modal-bg.webp';
import ModalBgPlaceholder from './assets/modal-bg.webp?placeholder';

import styles from './part-first-screen-banner.module.scss';

export default function PartFirstScreenBanner() {
  
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <div className={styles.banner}>
      <PartLazyImage
        src={BannerImage}
        placeholder={BannerImagePlaceholder}
        className={cx(styles.bannerImage, styles.bannerImage_desktop)}
        alt="banner image"
      />
      <PartLazyImage
        src={BannerImageMD}
        placeholder={BannerImagePlaceholderMD}
        className={cx(styles.bannerImage, styles.bannerImage_mobileMD)}
        alt="banner image"
      />
      <PartLazyImage
        src={BannerImageSM}
        placeholder={BannerImagePlaceholderSM}
        className={cx(styles.bannerImage, styles.bannerImage_mobileSM)}
        alt="banner image"
      />
      <div className={styles.bannerContent}>
        <h1>
          Modern,
          {' '}
          <br className="visibleOnPhone" />
          <i>Energy Star certified</i>
          {' '}
          <br />
          windows and doors
        </h1>
        <ul>
          <li>Lifetime Warranty</li>
          <li>Energy Efficient</li>
          <li>Helps Increase Home Value</li>
          <li>Custom Designed</li>
          <li>Help Save On Energy Bills</li>
        </ul>
        <a href="#" onClick={togglePopup} className={styles.serviceAreaLink}>
          <svg className={`${styles.serviceAreaIcon} mr5`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.364 17.364L12 23.728L5.636 17.364C4.37734 16.1053 3.52019 14.5017 3.17293 12.7558C2.82567 11.01 3.00391 9.20041 3.6851 7.55588C4.36629 5.91136 5.51984 4.50575 6.99988 3.51683C8.47992 2.5279 10.22 2.00006 12 2.00006C13.78 2.00006 15.5201 2.5279 17.0001 3.51683C18.4802 4.50575 19.6337 5.91136 20.3149 7.55588C20.9961 9.20041 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364ZM12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96084 13.4142 9.58577C13.0391 9.2107 12.5304 8.99998 12 8.99998C11.4696 8.99998 10.9609 9.2107 10.5858 9.58577C10.2107 9.96084 10 10.4696 10 11C10 11.5304 10.2107 12.0391 10.5858 12.4142C10.9609 12.7893 11.4696 13 12 13Z"/>
          </svg>
          <p className={styles.serviceAreaButton}>Our Service Area</p>
        </a>
      </div>
      {popupVisible && (
        <div className={styles.popupOverlay} onClick={togglePopup}>
          <div className={styles.popup}>
            <button className={styles.closeButton} onClick={togglePopup}>
              X
            </button>
            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${ModalBg})` }} />
              <div className={styles.popupContent}>
                <svg className={styles.popupIcon} xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.6067 25.94L15 36.5467L4.39334 25.94C2.29557 23.8422 0.866978 21.1695 0.288214 18.2598C-0.290549 15.35 0.00651141 12.3341 1.14183 9.59317C2.27715 6.85229 4.19974 4.50962 6.66647 2.86141C9.13321 1.2132 12.0333 0.333466 15 0.333466C17.9667 0.333466 20.8668 1.2132 23.3335 2.86141C25.8003 4.50962 27.7229 6.85229 28.8582 9.59317C29.9935 12.3341 30.2906 15.35 29.7118 18.2598C29.133 21.1695 27.7044 23.8422 25.6067 25.94ZM15 18.6667C15.8841 18.6667 16.7319 18.3155 17.357 17.6904C17.9821 17.0652 18.3333 16.2174 18.3333 15.3333C18.3333 14.4493 17.9821 13.6014 17.357 12.9763C16.7319 12.3512 15.8841 12 15 12C14.116 12 13.2681 12.3512 12.643 12.9763C12.0179 13.6014 11.6667 14.4493 11.6667 15.3333C11.6667 16.2174 12.0179 17.0652 12.643 17.6904C13.2681 18.3155 14.116 18.6667 15 18.6667Z" fill="#36162E"/>
                </svg>
                <h2 className={styles.serviceAreaTitle}>Our Service Area</h2>
              </div>
              <p className={`${styles.fz20} ${styles.lh16}`}>
                <span className={styles.fw500}> We work in and around all major cities in Southern Ontario </span>
                including Toronto, Oakville, Mississauga, Brampton, Aurora, Barrie, Richmond Hill, Thornhill, Whitby, Maple,
                Newmarket, Guelph, Pickering, Georgina, Orillia, Uxbridge, Hamilton, Welland and the Niagara <span role="img" aria-label="maple leaf">🍁</span>
              </p>
          </div>
        </div>
      )}
  </div>
 );
}
