import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import ImageTechnology01 from './assets/image-technology-01.webp';
import ImageTechnology02 from './assets/image-technology-02.webp';
import ImageTechnology03 from './assets/image-technology-03.webp';

import styles from './part-superior-technology.module.scss';

export default function PartSuperiorTechnology() {
  const technologies = [
    {
      imageSrc: ImageTechnology01,
      title: 'Low-E Glass',
      text: 'Reflects solar rays in the summer and retains infrared rays in the winter, helping to heat your home. Polaris Windows & Doors currently uses a 5th generation Low-E product',
      technologyNumber: '01',
    },
    {
      imageSrc: ImageTechnology02,
      title: 'Premium Vinyl',
      text: 'We build heavy-duty windows using premium, surgical-grade vinyl with no fillers or re-grind. This guarantees durability without warping, fading, or loss of performance.',
      technologyNumber: '02',
    },
    {
      imageSrc: ImageTechnology03,
      title: 'Advanced Spacer System',
      text: 'Any use of aluminum in window construction results in heat loss. To avoid this, Polaris Windows & Doors does not use any type of metal in its spacer systems.',
      technologyNumber: '03',
    },
  ];

  return (
    <div className={styles.partSuperiorTechnology}>
      <div className={styles.partSuperiorTechnology__content}>
        <div className={styles.partSuperiorTechnology__title}>
          SUPERIOR TECHNOLOGY
        </div>
        <div className={styles.partSuperiorTechnology__subTitle}>
          Replacing windows and doors with new energy-efficient models will save up to 50% on
          heating and cooling costs, and make your home feel more comfortable
        </div>
        <div className={styles.partSuperiorTechnology__technologiesList}>
          {technologies.map(({
            imageSrc,
            title,
            text,
            technologyNumber,
          }) => (
            <div
              key={title}
              className={styles.partSuperiorTechnology__technologiesListItemContainer}
            >
              <div className={styles.partSuperiorTechnology__technologiesListItem}>
                <div className={styles.partSuperiorTechnology__technologiesListItemInner}>
                  <PartLazyImage
                    src={imageSrc}
                    alt=""
                    className={styles.partSuperiorTechnology__technologiesListItemImage}
                  />
                  <div className={styles.partSuperiorTechnology__technologiesListItemTitle}>
                    {title}
                  </div>
                  <div className={styles.partSuperiorTechnology__technologiesListItemText}>
                    {text}
                  </div>
                  <div className={styles.partSuperiorTechnology__technologiesListItemNumber}>
                    {technologyNumber}
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
