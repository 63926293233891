import cx from 'classnames';
import React, { useState, useRef } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import PartLazyImage from '../../part-lazy-image';

import IconEye from './assets/icon-eye.svg';
import IconRewind from './assets/icon-rewind.svg';
import SliderImage01 from './assets/slider-image-01.webp';
import SliderImage02 from './assets/slider-image-02.webp';

import styles from './part-window-view-slider.module.scss';

export default function PartWindowViewSlider() {
  const [slideToShow, setSlideToShow] = useState(1);

  const titleRef = useRef();
  const buttonRef = useRef();

  const handleButtonClick = () => {
    setSlideToShow(slideToShow === 1 ? 2 : 1);
  };

  return (
    <div className={styles.partWindowViewSlider}>
      <SwitchTransition>
        <CSSTransition
          key={slideToShow === 1 ? 'YOUR VIEW COULD BE BETTER...' : 'NOW, THAT’S BETTER! 🍃'}
          classNames="fade"
          nodeRef={titleRef}
          timeout={500}
        >
          <div ref={titleRef}>
            <p className={styles.partWindowViewSlider__title}>
              {slideToShow === 1 ? 'YOUR VIEW COULD BE BETTER...' : 'NOW, THAT’S BETTER! 🍃'}
            </p>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div className={styles.partWindowViewSlider__window}>
        <PartLazyImage
          className={
              cx(
                styles.partWindowViewSlider__windowImage,
                styles.partWindowViewSlider__windowImage_1,
                { [styles.partWindowViewSlider__windowImage_visible]: slideToShow === 1 },
              )
            }
          src={SliderImage01}
        />
        <PartLazyImage
          className={
              cx(
                styles.partWindowViewSlider__windowImage,
                styles.partWindowViewSlider__windowImage_2,
                { [styles.partWindowViewSlider__windowImage_visible]: slideToShow === 2 },
              )
            }
          src={SliderImage02}
        />
      </div>

      <div className={styles.partWindowViewSlider__sliderToggleButtonsContainer}>
        <SwitchTransition>
          <CSSTransition
            key={slideToShow === 1 ? 'button1' : 'button2'}
            classNames="fade"
            nodeRef={buttonRef}
            timeout={500}
          >
            <div ref={buttonRef}>
              {slideToShow === 1 ? (
                <button
                  type="button"
                  key="button1"
                  className={cx(
                    styles.partWindowViewSlider__seeDifferenceButton,
                    styles.partWindowViewSlider__toggleButton,
                  )}
                  onClick={handleButtonClick}
                >
                  <IconEye />
                  <span>SEE THE DIFFERENCE</span>
                </button>
              ) : (
                <button
                  type="button"
                  key="button2"
                  className={cx(
                    styles.partWindowViewSlider__backButton,
                    styles.partWindowViewSlider__toggleButton,
                  )}
                  onClick={handleButtonClick}
                >
                  <IconRewind />
                </button>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}
